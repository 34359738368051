@media screen and (min-width: 767px) {
  .App {
    background-color: #181D23;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.App {
  background-color: #181D23;
}
.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
}
.fw-less-bold {
  font-weight: 500;
}
.active {
  background-color: #FDFB9F !important;
  color: #181D23 !important;
  font-weight: 700 !important;
}
@media screen and (min-width: 1000px) {
  .main-content {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0
  }
}
.btn-fwr {
  background-color: #FDFB9F !important;
  color: #181D23 !important;
  font-weight: bolder !important;
}
::placeholder {
  color: #FDFB9F !important;
  font-weight: lighter;
  font-size: small
}
.bg-fwr {
  background-color: #FDFB9F !important;
  color: #181D23 !important;
}
.text-fwr {
  color: #FDFB9F !important;
}
.border-fwr {
  border-color: #FDFB9F !important;
}
.cursor-pointer {
  cursor: pointer;
}
.overflow {
  overflow-x: auto !important;
}
@media screen and (max-width: 700px) {
  .table-responsive {
    overflow-x: auto;
    max-width: 360px;
    box-shadow: inset 0 0 5px rgba(150, 150 ,150,0.35);  
  }
}
@media screen and (max-width: 400px) {
  .table-responsive {
    overflow-x: auto;
    max-width: 320px;
    box-shadow: inset 0 0 5px rgba(150, 150 ,150,0.35);  
  }
}
@media screen and (max-width: 320px) {
  .table-responsive {
    overflow-x: auto;
    max-width: 272px;
    box-shadow: inset 0 0 5px rgba(150, 150 ,150,0.35);  
  }
}
.bg-dialog{
  background-color: #101418;
}
.text-loading {
  color: #585B5E !important;
}